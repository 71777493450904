/* eslint-disable react/prop-types,no-underscore-dangle */
import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { isMobile } from 'react-device-detect';
import Tippy from '@tippy.js/react';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import router from 'common/services/router';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import trans from 'common/services/translator';
import 'tippy.js/themes/light.css';
import { Link, withRouter } from 'react-router-dom';
import Input from './Part/Input.jsx';
import iconUser from '../../../icons/user.svg';

class SmsVerificationForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit() {
    document.querySelector('form').submit();
  }

  render() {
    const validationSchema = Yup.object().shape({
      token: Yup.string().required(trans.trans('This field is required'))
    });
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            token: ''
          }}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          onSubmit={this.submit}
          render={props => {
            return (
              <Form method="POST">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link className="login-link" to="/login">
                  {trans.trans('Back to sign in')}
                </Link>
                <div className="field-box">
                  <span>{trans.trans('SMS code')}</span>
                  <br />
                  <Field
                    name="token"
                    component={Input}
                    value={props.values.token}
                    icon={iconUser}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="token"
                  />
                </div>
                <p>
                  {this.props.identityVerification
                    ? trans.trans(
                        'You will get SMS soon. Please enter the verification code to confirm your login.'
                      )
                    : trans.trans(
                        'You will get SMS soon. Please enter the verification code to confirm your identity.'
                      )}
                </p>
                <div className="login-form-button-wrapper">
                  <button type="submit" className="login-form-button">
                    {trans.trans('SIGN IN')}
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SmsVerificationForm);
