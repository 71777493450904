/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, Redirect } from 'react-router-dom';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import router from 'common/services/router';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import trans from 'common/services/translator';
import axios from 'axios';
import * as Yup from 'yup';
import Input from './Part/Input.jsx';
import Message from './Part/Message.jsx';
import iconPassword from '../../../icons/lock.svg';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      error: null,
      success: null,
      smsSent: false,
      redirect: false,
      newPassword: null
    };
  }

  submit(values) {
    this.setState({
      error: null,
      success: null
    });
    if (!this.state.smsSent) {
      const endpoint = router.generate('backend_user_send_token');
      axios
        .get(`${endpoint}?hash=${this.props.hash}`)
        .then(r => {
          this.setState({
            success: trans.trans('Sms nr %nr% for date %date% is sent', {
              nr: r.data.number,
              date: r.data.date
            }),
            smsSent: true
          });
        })
        .catch(e => {
          if (e.response.status !== 400) {
            const message =
              e.response.data.error || 'Something went wrong, try again later';
            this.setState({
              error: trans.trans(message)
            });
          } else {
            this.setState({
              error: trans.trans('Correct validation errors')
            });
          }
        });
    } else {
      const endpoint = router.generate('backend_user_reseting_step2', {
        hash: this.props.hash
      });

      axios
        .post(endpoint, {
          token: values.token,
          plainPassword: values.password
        })
        .then(() => {
          this.setState({
            redirect: true,
            newPassword: values.password
          });
        })
        .catch(e => {
          if (e.response.status !== 400) {
            this.setState({
              error: trans.trans('Something went wrong, try again later')
            });
          } else {
            this.setState({
              error: e.response.data.error[0]
            });
          }
        });
    }
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              afterPassChange: true,
              username: document.getElementById('login-wrapper').dataset
                .username,
              newPassword: this.state.newPassword
            }
          }}
        />
      );
    }
    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required(trans.trans('This field is required'))
        .matches(
          /[A-ZĄĘŁÓŚŻŹĆŃ]/u,
          trans.trans('Password should have at least one capital letter')
        )
        .matches(
          /\d/u,
          trans.trans('Password should contain at least one digit')
        )
        .matches(
          /^.{8,64}$/u,
          trans.trans('Password length sgould be between 8 and 64')
        )
        .matches(
          /^[0-9A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż!@./#$%^&_\-?,*(){}[\]\\|:";'<>]*$/,
          trans.trans('Password contains incorrect characters')
        ),
      password2: Yup.string()
        .required(trans.trans('This field is required'))
        .oneOf(
          [Yup.ref('password'), null],
          trans.trans('Passwords have to be same')
        )
    });
    return (
      <React.Fragment>
        <div className="info-box">
          {trans.trans(
            'Set new password in application and confirm it with SMS code.'
          )}
        </div>
        {this.state.error && (
          <Message message={this.state.error} color="#ff143b" />
        )}
        {this.state.success && (
          <Message message={this.state.success} color="#2ecc5b" />
        )}
        <Formik
          initialValues={{
            password: '',
            password2: ''
          }}
          validateOnBlur
          validateOnChange
          onSubmit={this.submit}
          validationSchema={validationSchema}
          render={props => {
            return (
              <Form>
                <div style={{ paddingTop: '10px' }}>
                  <Link className="login-link" to="/login">
                    {trans.trans('Back to sign in')}
                  </Link>
                </div>
                <div className="field-box">
                  {trans.trans('New password')}
                  <br />
                  <Field
                    name="password"
                    component={Input}
                    value={props.values.password}
                    type="password"
                    icon={iconPassword}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="password"
                  />
                </div>
                <div className="field-box">
                  {trans.trans('Repeat password')}
                  <br />
                  <Field
                    name="password2"
                    component={Input}
                    value={props.values.password2}
                    type="password"
                    icon={iconPassword}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="password2"
                  />
                </div>
                {this.state.smsSent && (
                  <div className="field-box">
                    {trans.trans('SMS code')}
                    <br />
                    <Field
                      name="token"
                      autocomplete="one-time-code"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      component={Input}
                      id="Kod"
                      type="text"
                      className="one-time-code"
                      value={props.values.token}
                      icon={iconPassword}
                    />
                  </div>
                )}
                <div className="login-form-button-wrapper">
                  <button type="submit" className="login-form-button">
                    {this.state.smsSent
                      ? trans.trans('CHANGE PASSWORD')
                      : trans.trans('GENERATE SMS CODE')}
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

export default ChangePasswordForm;
