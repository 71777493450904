/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import trans from 'common/services/translator';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { Collapse } from 'reactstrap';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Cookies from 'universal-cookie';
import qs from 'qs';
import moment from 'moment';
import './styles/login-page.less';
import LoginForm from './LoginForm.jsx';
import ResetPasswordForm from './ResetPasswordForm.jsx';
import ChangePasswordForm from './ChangePasswordForm.jsx';
import Message from './Part/Message.jsx';
import BannerPanel from './BannerPanel.jsx';
import Logo from './Part/Logo.jsx';
import SmsVerificationForm from './SmsVerificationForm.jsx';

class LoginPage extends Component {
  PGO_USER_FINGERPRINT_COOKIE = 'pgo-fp-id';

  constructor(props) {
    super(props);
    this.state = {
      data: {
        banners: [],
        cssFile: null,
        loggedOut: false,
        isCollapseOpen: false,
        identityVerification: false
      }
    };

    this.setData = this.setData.bind(this);
  }

  componentDidMount() {
    const values = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });
    if (values.loggedOut) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loggedOut: true
      });
    }
    if (values.identity_verification) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        identityVerification: true
      });
    }
    const time = new Date().getTime();
    const bannersUrl =
      this.props.partner === 'PRAGMAGO'
        ? `${this.props.bannersUrl}?time=${time}`
        : `https://pragmago.pl/wp-json/pragma/v1/banners/partner?time=${time}`;
    axios.get(bannersUrl).then(response => {
      this.setData(response.data);
    });

    this.prepareFingerprint();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const values = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });
    if (values.identity_verification && !this.state.identityVerification) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        identityVerification: true
      });
    }
    if (!values.identity_verification && this.state.identityVerification) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        identityVerification: false
      });
    }
  }

  setData(data) {
    this.setState({
      data
    });
  }

  toggleCollapse = () => {
    const { isCollapseOpen } = this.state;
    this.setState({
      isCollapseOpen: !isCollapseOpen
    });
  };

  prepareFingerprint = () => {
    let pgoUserFingerprint = new Cookies().get(
      this.PGO_USER_FINGERPRINT_COOKIE
    );

    if (pgoUserFingerprint === undefined) {
      FingerprintJS.load({
        token: this.props.fingerprintToken
      })
        .then(fp => fp.get())
        .then(result => {
          pgoUserFingerprint = result.visitorId;

          new Cookies().set(
            this.PGO_USER_FINGERPRINT_COOKIE,
            pgoUserFingerprint,
            {
              path: '/',
              domain: this.props.domain,
              expires: moment()
                .add(1, 'year')
                .toDate()
            }
          );
        });
    }
  };

  render() {
    let messages = [];
    if (this.props.messages.error !== undefined) {
      messages = this.props.messages.error.map(m => (
        <Message key={m} message={trans.trans(m)} color="#ff143b" />
      ));
    }

    return (
      <Router>
        <div className={`login-container login-${this.props.partner}`}>
          <div>
            {this.state.data && this.state.data.cssFile && (
              <link
                rel="stylesheet"
                type="text/css"
                href={this.state.data.cssFile}
              />
            )}
            <img
              src="https://pragmago.pl/wp-content/themes/pragma/assets/img/dots.svg"
              alt=""
              className="icons__decoration--left"
            />
            <img
              src="https://pragmago.pl/wp-content/themes/pragma/assets/img/dots.svg"
              alt=""
              className="icons__decoration--right"
            />
            <div className="actions-container">
              <div className="login-form-box-outer">
                <div className="login-form-box-inner">
                  <Logo partner={this.props.partner} />
                  <div className="shadowed-box">
                    <Switch>
                      <Route path="/(login|crm|customer|admin)">
                        <h1 className="form-title">
                          {this.state.identityVerification
                            ? trans.trans('Unauthorised device or location')
                            : trans.trans('Signing in')}
                        </h1>
                      </Route>
                      <Route path="/resetting" exact>
                        <h1 className="form-title">
                          {trans.trans('Password resetting')}
                        </h1>
                      </Route>
                      <Route path="/resetting">
                        <h1 className="form-title">
                          {trans.trans('Password change')}
                        </h1>
                      </Route>
                    </Switch>
                    {messages}
                    <Switch>
                      <Route path="/(login|app_dev.php/login)">
                        <LoginForm
                          csrfToken={this.props.csrfToken}
                          lastUsername={this.props.lastUsername}
                        />
                      </Route>
                      <Route path="/login">
                        <LoginForm
                          csrfToken={this.props.csrfToken}
                          lastUsername={this.props.lastUsername}
                        />
                      </Route>
                      <Route path="/resetting" exact>
                        <ResetPasswordForm
                          csrfToken={this.props.csrfToken}
                          lastUsername={this.props.lastUsername}
                        />
                      </Route>
                      <Route path="/resetting">
                        <ChangePasswordForm
                          csrfToken={this.props.csrfToken}
                          lastUsername={this.props.lastUsername}
                          hash={this.props.hash}
                        />
                      </Route>
                      <Route path="/(crm|customer|admin)">
                        <SmsVerificationForm
                          csrfToken={this.props.csrfToken}
                          identityVerification={this.state.identityVerification}
                          lastUsername={this.props.lastUsername}
                          hash={this.props.hash}
                        />
                      </Route>
                    </Switch>
                    <hr />
                    <div className="login-help-info">
                      <div
                        className={`register-help-section ${
                          this.state.isCollapseOpen ? 'open' : ''
                        }`}
                      >
                        <div
                          className="register-help-section-controls"
                          onClick={this.toggleCollapse}
                          role="button"
                          tabIndex={0}
                        >
                          {trans.trans('How to register?')}
                          <span className="collapse-indicator">
                            {this.state.isCollapseOpen ? ' zwiń ' : ' rozwiń '}
                            <span className="chevron-down" />
                          </span>
                        </div>
                        <Collapse
                          className="register-help-section-collapse"
                          isOpen={this.state.isCollapseOpen}
                        >
                          Aby otrzymać dostęp do Strefy Klienta, skorzystaj z
                          naszych usług. Dane do logowania dostaniesz na maila,
                          którego podasz we wniosku.
                          <a
                            href="https://www.pragmago.pl/"
                            className="login-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong
                              dangerouslySetInnerHTML={{
                                __html: trans.trans('Check our offer')
                              }}
                            />
                          </a>
                        </Collapse>
                      </div>
                      {trans.trans('Need help?')}{' '}
                      {/* eslint-disable-next-line react/no-danger */}
                      <a
                        href="https://www.pragmago.pl/kontakt/"
                        className="login-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong
                          dangerouslySetInnerHTML={{
                            __html: trans.trans('Contact us')
                          }}
                        />
                      </a>
                      <div className="login-help-button-wrapper-row">
                        <a
                          href="https://pragmago.pl/bezpieczenstwo/"
                          className="login-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: trans.trans('Security link')
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ads-container">
              <BannerPanel
                partner={this.props.partner}
                banners={this.state.data.banners}
                loggedOut={this.state.loggedOut}
              />
            </div>
          </div>
          <div className="login-container-footerContainer">
            <div
              className="footer-text"
              dangerouslySetInnerHTML={{
                __html: trans.trans(
                  'Copyright &copy; 2002 - %YEAR% PragmaGO. All rights reserved.',
                  {
                    YEAR: new Date().getFullYear()
                  }
                )
              }}
            />
            <a
              href="https://pragmago.pl/polityka-prywatnosci/"
              className="footer-text login-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong
                dangerouslySetInnerHTML={{
                  __html: trans.trans('Privacy policy')
                }}
              />
            </a>
          </div>
        </div>
      </Router>
    );
  }
}

export default LoginPage;
