import React, { Component } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import router from 'common/services/router';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import trans from 'common/services/translator';
import axios from 'axios';
import Input from './Part/Input.jsx';
import Message from './Part/Message.jsx';
import iconUser from '../../../icons/user.svg';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      error: null,
      success: null,
      inProgress: false
    };
  }

  submit(values) {
    if (this.state.inProgress) {
      return;
    }
    this.setState({
      error: null,
      success: null,
      inProgress: true
    });
    const endpoint = router.generate('backend_user_reseting_request');
    axios
      .post(endpoint, values)
      .then(r => {
        this.setState({
          success: trans.trans(
            'If login is correct, a message with instructions to change the password was sent to the assigned e-mail address'
          ),
          inProgress: false
        });
      })
      .catch(e => {
        this.setState({
          error: trans.trans('Something went wrong, try again later'),
          inProgress: false
        });
      });
  }

  render() {
    const validationSchema = Yup.object().shape({
      username: Yup.string().required(trans.trans('This field is required'))
    });
    return (
      <React.Fragment>
        {this.state.error && (
          <Message message={this.state.error} color="#ff143b" />
        )}
        {this.state.success && (
          <Message message={this.state.success} color="#2ecc5b" />
        )}
        <Formik
          initialValues={{
            username: ''
          }}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          onSubmit={this.submit}
          render={props => {
            return (
              <Form>
                <div style={{ paddingTop: '10px' }}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link className="login-link" to="/login">
                    {trans.trans('Back to sign in')}
                  </Link>
                </div>
                <div className="field-box">
                  Login/e-mail
                  <br />
                  <Field
                    name="username"
                    component={Input}
                    value={props.values.username}
                    hidden
                    icon={iconUser}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="username"
                  />
                </div>
                <div className="login-form-button-wrapper">
                  <button
                    type="submit"
                    className={`login-form-button${
                      this.state.inProgress ? ' disabled' : ''
                    }`}
                    disabled={this.state.inProgress}
                  >
                    {trans.trans('CHANGE PASSWORD')}
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

export default ResetPasswordForm;
