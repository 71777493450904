/* CSS spinner - https://github.com/loadingio/css-spinner */

import React from 'react';
import './css/spinner.scss';

const Spinner = () => (
  <div className="lds-spinner">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
