/* eslint-disable */
import React, { Component } from 'react';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }
  render() {
    return (
      <div
        style={{
          display: this.state.open ? 'flex' : 'none',
          backgroundColor: this.props.color,
          color: 'white',
          fontSize: '1.1em',
          justifyContent: 'space-between',
          padding: '4px 10px'
        }}
      >
        <div dangerouslySetInnerHTML={{__html: this.props.message}} />
        <div>
          <i
            className="fa fa-times"
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ open: false })}
          />
        </div>
      </div>
    );
  }
}

export default Message;
