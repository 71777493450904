import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

const Banner = ({
  type,
  title,
  text,
  buttonLink,
  buttonText,
  buttonType,
  image,
  theme,
  size,
  titleColor,
  textColor,
  buttonTextColor,
  buttonTextHoverColor,
  buttonColor,
  buttonHoverColor,
  buttonBorderColor,
  buttonHoverBorderColor,
  backgroundSize,
  backgroundSolidColor,
  icon,
  logo,
  logoLeft,
  logoMaxWidth,
  imageLeft,
  imageWidth,
  imageHeight,
  hasShadow,
  justifyContent,
  customHeight
}) => {
  const [hover, setHover] = useState(false);

  let definedColor = buttonTextColor;
  let typeCheckBackground = buttonType === 'hex-color' ? buttonColor : null;
  let typeCheckBorder =
    buttonType === 'hex-color' ? `solid 2px ${buttonBorderColor}` : null;

  if (hover) {
    definedColor = !buttonTextHoverColor
      ? `${buttonColor}`
      : buttonTextHoverColor;
    typeCheckBackground = buttonType === 'hex-color' ? buttonHoverColor : null;
    typeCheckBorder =
      buttonType === 'hex-color' ? `solid 2px ${buttonHoverBorderColor}` : null;
  }
  const customHeightCheck = customHeight || null;
  return (
    <div
      className={`banner ${theme} ${size} ${hasShadow ? 'default-shadow' : ''}`}
      style={{
        background: `${backgroundSolidColor || `url(${image}) no-repeat`}`,
        backgroundSize,
        height:
          parseInt(customHeightCheck, 10) > 0 ? `${customHeightCheck}px` : null
      }}
    >
      <div
        className={`banner__wrapper ${logoLeft === '1' ? '' : 'logo-inverse'}`}
        style={{
          justifyContent: justifyContent || 'center'
        }}
      >
        {logo && (
          <div className="banner__logo-wrapper mobile">
            <img
              className="banner__logo"
              style={{ objectFit: 'contain', maxWidth: `${logoMaxWidth}px` }}
              src={logo}
              alt={title}
            />
          </div>
        )}
        {size === 'banner--xLarge' && (
          <Fragment>
            <div className="banner__logo-wrapper desktop">
              <img
                className="banner__logo"
                style={{ objectFit: 'contain', maxWidth: `${logoMaxWidth}px` }}
                src={logo}
                alt={title}
              />
            </div>
            <div
              className="banner__title"
              dangerouslySetInnerHTML={{ __html: title }}
              style={{ color: titleColor }}
            />
          </Fragment>
        )}
        <div
          className={`banner__wrapper-inner ${
            imageLeft === '1' ? 'inverse' : ''
          }`}
        >
          <div
            className="banner__left-column"
            style={{
              maxWidth: `${icon ? '70%' : '100%'}`
            }}
          >
            {logo && logoLeft === '1' && size !== 'banner--xLarge' && (
              <div className="banner__logo-wrapper desktop">
                <img
                  className="banner__logo"
                  style={{
                    objectFit: 'contain',
                    maxWidth: `${logoMaxWidth}px`
                  }}
                  src={logo}
                  alt={title}
                />
              </div>
            )}
            {size !== 'banner--xLarge' && (
              <div
                className="banner__title"
                dangerouslySetInnerHTML={{ __html: title }}
                style={{ color: titleColor }}
              />
            )}
            {text && (
              <div
                className="banner__text"
                dangerouslySetInnerHTML={{ __html: text }}
                style={{ color: textColor }}
              />
            )}
            <a
              href={buttonLink}
              className={`banner__button banner__button--${buttonType}`}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{
                color: `${buttonType === 'hex-color' ? definedColor : null}`,
                backgroundColor: `${typeCheckBackground}`,
                border: `${typeCheckBorder}`
              }}
            >
              {buttonText}
            </a>
          </div>
          {icon && (
            <div className="banner__right-column desktop">
              {logo && logoLeft !== '1' && size !== 'banner--xLarge' && (
                <div className="banner__logo-wrapper">
                  <img
                    className="banner__logo"
                    style={{
                      objectFit: 'contain',
                      maxWidth: `${logoMaxWidth}px`
                    }}
                    src={logo}
                    alt={title}
                  />
                </div>
              )}
              <img
                className="banner__icon"
                style={{
                  objectFit: 'contain',
                  width: `${imageWidth ? `${imageWidth}px` : '100%'}`,
                  height: `${imageHeight ? `${imageHeight}px` : '100%'}`
                }}
                src={icon}
                alt={title}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  buttonType: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  theme: PropTypes.string,
  size: PropTypes.string,
  titleColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonTextHoverColor: PropTypes.string,
  buttonColor: PropTypes.string,
  backgroundSize: PropTypes.string,
  icon: PropTypes.string,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
  logo: PropTypes.bool,
  logoLeft: PropTypes.string,
  imageLeft: PropTypes.string,
  hasShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttonHoverColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonHoverBorderColor: PropTypes.string,
  backgroundSolidColor: PropTypes.string,
  logoMaxWidth: PropTypes.string,
  justifyContent: PropTypes.string,
  customHeight: PropTypes.string
};

Banner.defaultProps = {
  type: 'normal',
  title: '',
  text: '',
  buttonLink: '',
  buttonText: '',
  buttonType: '',
  image: '',
  theme: '',
  size: '',
  titleColor: '',
  textColor: '',
  buttonTextColor: '',
  buttonTextHoverColor: '',
  buttonColor: '',
  backgroundSize: '',
  icon: '',
  imageHeight: null,
  imageWidth: null,
  logo: '',
  logoLeft: 1,
  imageLeft: 0,
  hasShadow: null,
  buttonHoverColor: null,
  buttonBorderColor: null,
  buttonHoverBorderColor: null,
  backgroundSolidColor: null,
  logoMaxWidth: null,
  justifyContent: null,
  customHeight: null
};

export default Banner;
