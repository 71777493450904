import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const Input = ({
  type,
  value,
  placeholder,
  prefix,
  mask,
  className,
  id,
  form,
  disabled,
  field,
  inputMode,
  readonly,
  onChange,
  onBlur,
  icon,
  pattern,
  autocomplete
}) => {
  return (
    <div className="login-input-field">
      <InputMask
        type={type}
        id={id}
        className={className}
        value={value}
        name={field.name}
        mask={mask}
        autocomplete={autocomplete}
        onBlur={e => {
          field.onBlur(e);
          onBlur(e.target.value);
        }}
        onChange={e => {
          form.handleChange(e);
          onChange(e.target.value);
        }}
        pattern={pattern}
        placeholder={placeholder}
        disabled={disabled}
        inputMode={inputMode}
        readOnly={readonly}
      />
      <span className="icon">
        <img src={icon} alt="icon" />
      </span>
      {prefix ? <span className="prefix">{prefix}</span> : ''}
      <br />
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  inputMode: PropTypes.string,
  prefix: PropTypes.string,
  mask: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  pattern: PropTypes.string,
  autocomplete: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

Input.defaultProps = {
  type: 'text',
  value: null,
  placeholder: null,
  inputMode: 'text',
  prefix: '',
  mask: '',
  className: '',
  id: '',
  icon: '',
  autocomplete: null,
  pattern: null,
  disabled: false,
  readonly: false,
  onChange: () => {},
  onBlur: () => {}
};

export default Input;
