/* eslint-disable react/prop-types,no-underscore-dangle */
import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { isMobile } from 'react-device-detect';
import Tippy from '@tippy.js/react';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import router from 'common/services/router';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import trans from 'common/services/translator';
import 'tippy.js/themes/light.css';
import { Link, withRouter } from 'react-router-dom';
import Input from './Part/Input.jsx';
import Message from './Part/Message.jsx';
import iconUser from '../../../icons/user.svg';
import iconPassword from '../../../icons/lock.svg';
import Spinner from '../../../../../../../Frontend/CoreBundle/Resources/public/react/components/Dropzone/Spinner';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state?.afterPassChange) {
      this.autologin();
    }
  }

  submit() {
    document.querySelector('form').submit();
  }

  autologin() {
    this.submit();
  }

  render() {
    const validationSchema = Yup.object().shape({
      _username: Yup.string().required(trans.trans('This field is required')),
      _password: Yup.string().required(trans.trans('This field is required'))
    });
    return (
      <React.Fragment>
        {this.props.location.state?.afterPassChange && (
          <h3>
            Czekaj <Spinner />
          </h3>
        )}
        <div
          className={this.props.location.state?.afterPassChange ? 'd-none' : ''}
        >
          {this.props.location.state &&
            this.props.location.state.afterPassChange && (
              <Message
                message={trans.trans('Password changed')}
                color="#2ecc5b"
              />
            )}

          <Formik
            initialValues={{
              // eslint-disable-next-line @typescript-eslint/camelcase
              _csrf_token: this.props.csrfToken,
              _username: this.props.location.state?.afterPassChange
                ? this.props.location.state.username
                : this.props.lastUsername,
              _password: this.props.location.state?.afterPassChange
                ? this.props.location.state.newPassword
                : ''
            }}
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            onSubmit={this.submit}
            render={props => {
              return (
                <Form
                  method="POST"
                  action={router.generate('backend_user_security_check')}
                >
                  <Field
                    name="_csrf_token"
                    component="input"
                    type="hidden"
                    value={props.values._csrf_token}
                    hidden
                  />
                  <div className="field-box">
                    <span>{trans.trans('Email or login')}</span>
                    <Tippy
                      content={
                        <div>
                          <span
                            className="fa fa-info-circle"
                            style={{ color: '#3799e0', fontSize: '1em' }}
                          />
                          <br />
                          <span style={{ fontSize: '0.8em' }}>
                            {trans.trans(
                              'We have sent you your login via e-mail.'
                            )}
                            <br />
                            {trans.trans('Most often it is an email address.')}
                          </span>
                        </div>
                      }
                      triggerTarget={document.getElementById(
                        'login-label-tooltip-trigger'
                      )}
                      placement={isMobile ? 'top' : 'right-start'}
                      offset={isMobile ? '' : '10, 15'}
                      theme="light pragmago"
                      arrow
                      allowHTML
                    >
                      <sup id="login-label-tooltip-trigger">
                        <span
                          className="fa fa-info-circle"
                          style={{ color: '#3799e0', fontSize: '1.25em' }}
                        />
                      </sup>
                    </Tippy>
                    <br />
                    <Field
                      name="_username"
                      component={Input}
                      value={props.values._username}
                      icon={iconUser}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger"
                      name="_username"
                    />
                  </div>
                  <div className="field-box">
                    {trans.trans('Password')}
                    <br />
                    <Field
                      name="_password"
                      component={Input}
                      type="password"
                      value={props.values._password}
                      icon={iconPassword}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger"
                      name="_password"
                    />
                  </div>
                  <Link className="login-link" to="/resetting">
                    {trans.trans('Password resetting')}
                  </Link>
                  <div className="login-form-button-wrapper">
                    <button type="submit" className="login-form-button">
                      {trans.trans('SIGN IN')}
                    </button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginForm);
