import React from 'react';
import PropTypes from 'prop-types';
import Banner from './Part/Banner.jsx';

const BANNER_SIZE_LARGE = 'banner--large';
const BANNER_SIZE_X_LARGE = 'banner--xLarge';
const BANNER_SIZE_SMALL = 'banner--small';

const renderRow = (bannerRow, idx) => {
  return (
    <div className="d-flex" key={idx}>
      {bannerRow.map(banner => (
        <Banner
          key={banner.title}
          type={banner.type}
          title={banner.title}
          text={banner.text}
          justifyContent={banner.justifyContent}
          buttonLink={banner.button_link}
          buttonText={banner.button_text}
          buttonType={banner.button_type}
          titleColor={banner.title_color}
          textColor={banner.text_color}
          buttonTextColor={banner.button_text_color}
          buttonTextHoverColor={banner.button_text_hover_color}
          buttonColor={banner.button_color}
          buttonHoverColor={banner.buttonHoverColor}
          buttonBorderColor={banner.buttonBorderColor}
          buttonHoverBorderColor={banner.buttonHoverBorderColor}
          image={banner.image}
          theme={banner.theme}
          size={banner.size}
          backgroundSize={banner.background_size}
          backgroundSolidColor={banner.backgroundSolidColor}
          icon={banner.icon}
          logo={banner.logo}
          logoLeft={banner.logoLeft}
          logoMaxWidth={banner.logoMaxWidth}
          imageLeft={banner.imageLeft}
          imageWidth={banner.imageWidth}
          imageHeight={banner.imageHeight}
          hasShadow={banner.hasShadow}
          customHeight={banner.customHeight}
        />
      ))}
    </div>
  );
};

const BannerPanel = ({ banners, partner, loggedOut }) => {
  const bannerRows = [];
  let bannersFilteredByPartner = banners;
  if (partner !== 'PRAGMAGO') {
    bannersFilteredByPartner = banners.slice().filter(banner => {
      return banner.partner === partner;
    });
  }
  bannersFilteredByPartner = bannersFilteredByPartner
    .slice()
    .filter(
      banner =>
        banner.logOut === 'default' ||
        banner.logOut === (loggedOut ? 'logout' : 'login')
    );
  if (bannersFilteredByPartner.length > 0) {
    let newRow = true;
    bannersFilteredByPartner.forEach(banner => {
      if (newRow) {
        bannerRows.push([banner]);
        newRow =
          BANNER_SIZE_LARGE === banner.size ||
          BANNER_SIZE_X_LARGE === banner.size;
      } else {
        bannerRows[bannerRows.length - 1].push(banner);
        newRow = true;
      }
    });
  }

  return (
    <div className="banner-container">
      {bannerRows.map((bannerRow, index) => renderRow(bannerRow, index))}
    </div>
  );
};

BannerPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  banners: PropTypes.array,
  partner: PropTypes.string,
  loggedOut: PropTypes.bool
};

BannerPanel.defaultProps = {
  banners: [],
  partner: '',
  loggedOut: false
};

export default BannerPanel;
