import React, { Component } from 'react';

class Logo extends Component {
  render() {
    let logo;
    // eslint-disable-next-line react/prop-types
    switch (this.props.partner) {
      case 'PRAGMAGO':
        logo = (
          <div className="logo-box">
            <img
              src="https://cdn.pragma.pl/mail/common/pragma-go-logo.png"
              alt="Logo"
            />
          </div>
        );
        break;
      case 'HITTRANS':
        logo = (
          <div className="logo-box" style={{ height: '150px' }}>
            <img
              width="110px"
              src="https://cdn.pragma.pl/mail/hittrans/hittrans-logo.png"
              alt="Logo"
            />
          </div>
        );
        break;
      case '3W':
        logo = (
          <div className="logo-box">
            <img
              width="130px"
              src="https://cdn.pragma.pl/mail/common/3w-logo.png"
              alt="Logo"
            />
          </div>
        );
        break;
      default:
        logo = (
          <div className="logo-box">
            <img
              src="http://cdn.pragma.pl/mail/common/pragma-go-logo.png"
              alt="Logo"
            />
          </div>
        );
    }

    return logo;
  }
}

export default Logo;
