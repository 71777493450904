// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import 'commonStyles/pragmago.less';
import React from 'react';
import ReactDOM from 'react-dom';
import LoginPage from './LoginPage.jsx';

const container = document.getElementById('login-wrapper');
const {
  csrf,
  username,
  messages,
  hash,
  partner,
  bannersUrl,
  domain,
  fingerprintToken
} = container.dataset;
ReactDOM.render(
  <LoginPage
    csrfToken={csrf}
    lastUsername={username}
    messages={JSON.parse(messages || '[]')}
    hash={hash}
    partner={partner}
    bannersUrl={bannersUrl}
    domain={domain}
    fingerprintToken={fingerprintToken}
  />,
  container
);
